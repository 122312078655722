/**
 * @fileoverview Initializes the scoreboard.
 */

/** @define {string} API Key for CTF scoreboard */
const API_KEY = 'AIzaSyC619dSNfCvV05h4ftN5uftS-JbTCFjDwo'
/** @define {string} Auth Domain for CTF scoreboard */
const AUTH_DOMAIN = 'inspiring-keel-428123-c7.firebaseapp.com'
/** @define {string} Database URL for CTF scoreboard */
const DATABASE_URL = 'https://inspiring-keel-428123-c7-default-rtdb.firebaseio.com/'
/** @define {string} Project ID for CTF scoreboard */
const PROJECT_ID = 'inspiring-keel-428123-c7'
/** @define {string} Storage Bucket for CTF scoreboard */
const STORAGE_BUCKET = 'inspiring-keel-428123-c7.appspot.com'
const MESSAGE_SENDER_ID = '261281432422'
const APP_ID = '1:261281432422:web:169fa0b3663d4d799bfea7'

import { initializeApp } from '../_snowpack/pkg/firebase/app.js'
import Scoreboard from './scoreboard.js'

initializeApp({
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGE_SENDER_ID,
  appId: APP_ID
})

new Scoreboard().init()
